<template>
  <div class="p-2 pt-8" >
    <Table  />
  </div>
</template>
<script>
import Table from "@/components/Component/Core/Farmer/TableFarmer.vue";
export default {
  name: "buffalo-all-page",
  components: {
    Table
  },
};
</script>